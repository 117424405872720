.custom-navbar {
    background-color: #051590;
    margin: 0;
}

.nav-image {
    width: 15%;
    height: auto;
    object-fit: cover;
    display: block;
}

nav {
    position: relative;
    background-color: #051590;
}

nav a {
    color: white !important;
}

.navbar-nav .nav-link {
    transition: background-color 0.3s ease;
    border-radius: 50px;
}

.navbar-nav .nav-link:hover {
    background-color: #e71a28;
    color: white;
    border-radius: 50px;
}

.home-li {
    margin-left: 10px;
}

ul p {
    color: white;
    justify-content: center;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .nav-image {
        width: 30%;
        height: auto;
    }

    .navbar-nav .nav-link {
        margin-bottom: 10px;
    }

    .navbar-nav {
        width: 100%;
        text-align: center;
    }

    .nav {
        text-align: center;
        margin: 10px 0;
    }
}
