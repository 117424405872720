/* Estilizando exclusivamente o formulário */
.registration-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px 30px;
    width: 100%;
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.registration-container h1 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.registration-container .registration-form {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 900px; /* Ajuste conforme necessário */
}

.registration-container .registration-form label {
    flex: 1 1 30%; /* Ajuste conforme necessário */
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #555;
}

.registration-container .registration-form input,
.registration-container .registration-form select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s;
}

.registration-container .registration-form input:focus,
.registration-container .registration-form select:focus {
    border-color: #007BFF;
}

.registration-container .registration-form button {
    padding: 10px 15px;
    background-color: #007BFF;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
}

.registration-container .registration-form button:hover {
    background-color: #0056b3;
}
