.custom-modal {
    width: 50%;
    background-color: #fff; /* Defina a cor de fundo desejada */
    border: 2px solid rgba(0, 0, 0, 0.2); /* Adicione a borda desejada */
    border-radius: 7px; /* Adicione o raio de borda desejado */
    padding: 20px;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-modal form {
    margin-bottom: 20px; /* Adicione margem inferior para os formulários dentro da modal */
}

.custom-modal label {
    display: block; /* Para que cada label apareça em uma linha separada */
    margin-bottom: 10px; /* Adicione margem inferior para separar os labels */
}

.custom-modal input {
    width: 100%; /* Define a largura total para os inputs */
    margin-bottom: 10px; /* Adicione margem inferior para separar os inputs */
}


.btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    color: #000;
    opacity: 0.5;
}

.btn-close:hover {
    opacity: 1;
}

.custom-button {
    position: fixed; /* Posiciona o botão de forma fixa na tela */
    bottom: 20px; /* Espaço do fundo para o botão */
    right: 20px; /* Espaço da direita para o botão */
    z-index: 1000; /* Garante que o botão fique acima dos outros elementos */
}

.custom-button {
    position: fixed; /* Posiciona o botão de forma fixa na tela */
    bottom: 20px; /* Espaço do fundo para o botão */
    right: 20px; /* Espaço da direita para o botão */
    z-index: 1000; /* Garante que o botão fique acima dos outros elementos */
}
.Btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 45px;
    height: 45px;
    border-radius: calc(45px/2);
    border: none;
    cursor: pointer;
    overflow: hidden;
    transition-duration: .3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
    background: linear-gradient(144deg,#6693d3,#6693d3, 50%,#6693d3);
  }
  
  
  /* plus sign */
  .sign {
    width: 100%;
    font-size: 2.5em;
    color: black;
    transition-duration: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
  }
  /* text */
  .text {
    right: 0%;
    width: 0%;
    opacity: 0;
    color: black;
    font-size: 1.5em;
    font-weight: 500;
    transition-duration: .3s;
    margin-bottom: 5px;
  }
  /* hover effect on button width */
  .Btn:hover {
    width: 200px;
    transition-duration: .3s;
  }
  
  .Btn:hover .sign {
    width: 30%;
    transition-duration: .3s;
    padding-left: 15px;
  }
  /* hover effect button's text */
  .Btn:hover .text {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 15px;
  }
  /* button click effect*/
  .Btn:active {
    transform: translate(2px ,2px);
  }

.search-container {
  margin-top: 10px;
}

.title{ 
  text-align: center;
}

/* Media Queries para Responsividade */
/* Estilos para telas menores que 768px */
@media (max-width: 768px) {
  .custom-button {
    bottom: 10px;
    right: 10px;
  }
  .Btn {
    width: 35px;
    height: 35px;
    border-radius: calc(35px/2);
  }
  .sign {
    font-size: 2em;
  }
  .text {
    font-size: 1em;
  }
  .Btn:hover {
    width: 150px;
  }
  .Btn:hover .sign {
    width: 40%;
    padding-left: 10px;
  }
  .Btn:hover .text {
    width: 60%;
    padding-right: 10px;
  }
  .search-container {
    width: 90%;
    margin: 10px auto;
  }
  .table {
    width: 100%;
  }
  .table th, .table td {
    font-size: 0.8em;
  }

  .edit-button{
    margin: 1%;
  }
  
  .invert-button{
    margin: 1%;
  }
  
  .action-td {
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }
}